@media screen and (max-width: 750px) {
    .section {
        width: 100%;
        margin-top: 30px;
    }
    .button-LearnMore {
        justify-self: right;
    }
    .liquidity-wrapper {
        width: 100%;
        justify-content: center;
        display: flex;
        margin-top: 100px;
        padding-bottom: 150px;
        margin-left: 200px;
        opacity: 0;
    }
    .liquidity-inner-wrapper {
        width: 92%;
    }
    .section-holder-outer {
        margin-top: 50px;
    }
    .external-link {
        display: flex;
        width: 95%;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 750px) {
    .section-holder-inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
    .section-holder-outer {
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .section {
        display: flex;
        flex-direction: column;
        width: 48%;
    }
    .liquidity-inner-wrapper {
        width: 84%;
        max-width: var(--max-screen-width);
    }
    .liquidity-wrapper {
        width: 100%;
        justify-content: center;
        display: flex;
        margin-top: 180px;
        opacity: 0;
        margin-left: 260px;
    }
    .liquidity-content-wrapper {
        max-width: 750px;
        overflow-wrap: break-word;
    }
}

.carbon-header-holder {
    display: flex;
}

.carbon-title-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-primary);
}

.section-holder-outer {
    width: 100%;
}

.liquidity-show {
    opacity: 1;
    transition: 2s;
    margin-left: 0;
}