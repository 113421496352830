@media screen and (max-width: 446px) {
    .node-text-wrapper {
        width: 125px;
    }

    .node-text-wrapper-hovered {
        width: 125px;
    }
    .best-node-moniker {
        width: 65px;
    }
    .node-rating {
        margin-left: 15px;
    }
}

@media screen and (min-width: 446px) {
    .node-link {
        width: 185px;
    }

    .best-node-moniker {
        width: 133px;
    }

    .normal-node-moniker {
        width: 182px
    }

    .node-text-wrapper {
        width: 182px;
    }

    .node-text-wrapper-hovered {
        width: 182px;
    }
    .node-rating {
        margin-left: 29px;
    }
}

.node-row-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
}

.node-text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 2px;
}

.node-text-wrapper-hovered {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 2px;
    background: linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 85.59%, #DAFFF4 132.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.node-link {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.node-name-wrapper {
    display: flex;
    gap: 7px;
}

.best-node {
    width: 42px;
    height: 20px;
    border: 1px solid #3C9D40;
    padding: 4px 0px;
    border-radius: 8px;
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: #3C9D40;
    background-clip: text;
    background-color: #3C9D40;
}

.node-moniker {
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-primary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.node-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
}

.node-tooltip-text {
    color: #3C9D40;
}