.bridge-history-card-inner-wrapper {
    width: 342px;
    background: var(--color-bg-primary);
    border: 1px solid var(--color-transparent);
    border-radius: 20px;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 16px;
}

.bridge-history-card-top {
    height: 80px;
    width: 100%;
    padding: 20px 16px 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-transparent);
}

.bridge-history-card-text-secondary {
    color: var(--color-text-secondary);
    font-size: 14px;
    line-height: 18px;
}

.date-time {
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
}

.bridge-history-card-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 16px 24px;
    gap: 8px;
}

.bridge-history-card-bottom div {
    display: flex;
    justify-content: space-between;
}

.bridge-history-card-disabled * {
    opacity: 0.5;
}

.bridge-history-card-disabled span {
    opacity: 1;
    color: #94ABB4;
}

.bridge-history-label-box {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.bridge-history-card-status-label {
    display: flex;
    gap: 5.5px;
    align-items: center;
    position: relative;
}

.bridge-history-card-status {
    display: flex;
    align-items: center;
}
