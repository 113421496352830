.stake-validator-card-wrapper {
    border-radius: 20px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    height: fit-content;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
}

.validator-card-identity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 16px;
}

.validator-card-stats {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
    width: 100%;
}

.validator-card-stats>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.stake-validators-card-cumulative-chart {
    display: flex;
    width: 80px;
    height: 18px;
    border-radius: 4px;
    background: var(--color-bg-disabled);
    overflow: hidden
}

.stake-validators-card-cumulative-chart> :nth-child(1) {
    background: #f1f7fc;
    height: 100%;
    border-radius: 4px;
}

.stake-validators-card-cumulative-chart> :nth-child(2) {
    background: var(--color-branding-secondary);
    height: 100%;
}

.delegate-button-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.delegate-button-wrapper:hover {
    color: var(--color-branding-secondary);
}