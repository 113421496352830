.tabbar {
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--color-transparent);
}

.tabbar-tab-blur {
    padding-bottom: 14px;
    cursor: pointer;
}

.tabbar-tab-focus {
    padding-bottom: 16px;
    cursor: pointer;
    border-bottom: 2px solid var(--color-primary);
}
