@media screen and (max-width: 750px) {
    .bridge-card {
        min-width: 300px;
        max-width: 650px;
        margin: 60px 30px;
        padding: 20px 20px;
    }

    .from-to {
        margin: 16px 0;
    }

    .from-to #bridge-icon {
        display: none;
        margin-bottom: 12px;
    }

    #to {
        margin-top: 16px;
    }

    .bridge-balance-box {
        font-size: 12px;
    }

    .bridge-estimations {
        margin: 16px 0;
    }
}

@media screen and (min-width: 750px) {
    .bridge-card {
        width: 600px;
        padding: 30px 30px;
    }

    .dropdown-wrapper {
        width: 200px;
    }

    .from-to {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0px;
    }

    .from-to #bridge-icon {
        margin-top: 25px;
        cursor: pointer;
    }

    .bridge-balance-box {
        font-size: 14px;
    }

    .bridge-estimations {
        margin: 24px 0;
    }
}

.from-to {
    width: 100%;
}

.dropdown-wrapper {
    font-size: 14px;
}

.wallet-address-holder {
    background-color: var(--color-bg-primary);
    border: 1px solid var(--color-transparent);
    margin-top: 8px;
    font-weight: 700;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.wallet-address-holder:hover {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.dropdown-wrapper button {
    font-size: 14px;
    width: 100%;
    height: 48px;
    margin-top: 8px;
}

.bridge-card-wrapper::-webkit-scrollbar {
    display: none;
}

.bridge-card {
    border-radius: 20px;
    border: 1px solid var(--color-transparent);
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    margin-top: 110px;
    height: fit-content;
    color: var(--color-primary);
    margin-bottom: 88px;
}

.bridge-card-header {
    font-size: 20px;
    margin-bottom: 24px;
    width: 100%;
}

.bridge-card-dropdown {
    margin: 4px 0;
}

.bridge-amount,
.bridge-warning {
    width: 100%;
}

.bridge-amount-disabled {
    opacity: 0.5;
}

#bottom-button {
    margin-top: 16px;
    width: 100%;
    font-size: 14px;
    height: 48px;
}

.bridge-numbers-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    padding-left: 2px;
}

.bridge-numbers-bar span {
    cursor: default;
}

:not(.bridge-amount-disabled) > .bridge-numbers-bar span {
    cursor: pointer;
}


:not(.bridge-amount-disabled) > .bridge-numbers-bar span:hover {
    color: var(--color-branding-secondary)
}

.bridge-balance-box {
    color: var(--color-text-secondary);
    font-family: Heebo;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
}

.bridge-balance {
    display: flex;
    justify-content: space-between;
    line-height: 18px;
    width: 100%;
    gap: 8px;
}

.input-error {
    color: var(--color-error);
    font-family: 'Heebo';
    font-weight: 400;
}

.bridge-estimations {
    width: 100%;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
}

.bridge-estimations div {
    display: flex;
    justify-content: space-between;
}

.bridge-estimations div:first-child {
    margin-bottom: 7px;
}

.bridge-estimations div:nth-child(3) {
    color: var(--color-text-secondary);
    font-size: 14px;
}

.bridge-amount-input {
    border: 1px solid var(--color-transparent);
    height: 60px;
    border-radius: 12px;
    margin-top: 8px;
    display: flex;
}

:not(.bridge-amount-disabled) > .bridge-amount-input:hover,
:not(.bridge-amount-disabled) > .active-border {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.bridge-amount-input [type="text"] {
  display: block;
  border: none;
  height: 100%;
  width: 90%;
  font-family: 'Tyros Pro';
  font-size: 20px;
  font-weight: 700;
  color: #0a3f52;
  margin-left: 8px;
  /* Firefox */
  -moz-appearance: textfield;
}


/* Chrome, Safari, Edge, Opera */

.bridge-amount-input [type="text"]::-webkit-outer-spin-button,
.bridge-amount-input [type="text"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tokens-container {
    display: flex;
    align-items: center;
    font-size: 16Px;
    font-weight: 400;
    font-family: Heebo;
}

.token-dropdown > .dropdown-header { 
    border: none !important;
    min-width: max-content;
    gap: 12px;
    background:none;
}

input[type=range] {
    width: 99%;
    margin-top: 5px;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    border-radius: 4px;
}

input[type=range]:not([disabled])::-webkit-slider-runnable-track {
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
    -webkit-appearance: none;
    margin-top: -7.5px;
}

input[type=range]:not([disabled])::-webkit-slider-thumb {
    cursor: pointer;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    background: var(--color-bg-disabled);
    border-radius: 4px;
}

input[type=range]:not([disabled])::-moz-range-track {
    cursor: pointer;
}

input[type=range]::-moz-range-progress {
    background: var(--color-primary);
    height: 4px;
}

input[type=range]::-moz-range-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
}

input[type=range]:not([disabled])::-moz-range-thumb {
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]:not([disabled])::-ms-track {
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: var(--color-primary);
    border-radius: 4px;
}

input[type=range]::-ms-fill-upper {
    background: var(--color-bg-disabled);
    border-radius: 4px;
}

input[type=range]::-ms-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
}

input[type=range]:not([disabled])::ms-thumb {
    cursor: pointer;
}

.bridge-card-msg-wrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.bridge-refresh-rotate {
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
}

.bridge-refresh-pointer {
    cursor: pointer;
}

@-moz-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
