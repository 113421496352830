@media screen and (max-width: 750px) {
    .bridge-card-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;

    }
}

@media screen and (min-width: 750px) {
    .bridge-card-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Token_Desktop_1.svg'), url('assets/Backgrounds/Migrate_Token_Desktop_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top, left bottom;
    }
}

.bridge-card-wrapper {
    display: flex;
    justify-content: center;
    min-width: 300px;
    padding-top: 110px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    font-family: Tyros Pro;
}

.bridge-card-wrapper::-webkit-scrollbar {
    display: none;
}

.bridge-toggle-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;
    gap: 4px;
    position: absolute;
    width: 229px;
    height: 48px;
    left: 50%;
    top: 148px;
    transform: translate(-50%, 0);
    border: 1px solid var(--color-transparent);
    border-radius: 12px;
}

.bridge-toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 108px;
    height: 34px;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.bridge-toggle-active {
    background: var(--color-primary);
    color: var(--color-bg-primary);
}
