.home-page-wrapper {
    position: relative;
    overflow-x: hidden;
    min-width: 300px;
    padding-top: 70px;
    background-repeat: no-repeat;
}

@media screen and (max-width: 750px) {
    .home-page-wrapper {
        background-image: url('assets/Backgrounds/Home_Mobile_1.svg'), url('assets/Backgrounds/Home_Mobile_4.svg');
        background-position: top 70px left, bottom right;
    }
}

@media screen and (min-width: 750px) and (max-width: 2200px) {
    .home-page-wrapper {
        background-image: url('assets/Backgrounds/Home_Desktop_1.svg'), url('assets/Backgrounds/Home_Desktop_2.svg'), url('assets/Backgrounds/Home_Desktop_3.svg'), url('assets/Backgrounds/Home_Desktop_4.svg');
        background-position: top 70px left, bottom 1750px right, bottom 500px left, bottom right;
    }
}

@media screen and (min-width: 2200px) {
    .home-page-wrapper {
        background-image: url('assets/Backgrounds/Home_Wide_1.svg'), url('assets/Backgrounds/Home_Wide_2.svg'), url('assets/Backgrounds/Home_Wide_3.svg'), url('assets/Backgrounds/Home_Wide_4.svg');
        background-position: top 70px left, bottom 1500px right, bottom 260px left, bottom right;
    }
}