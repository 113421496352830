@media screen and (max-width: 650px) {
    .address {
        font-size: 11px;
    }
    .migrate-button-wrapper-desktop {
        display: none;
    }
    .migrate-card-header {
        font-size: 14px;
    }
}

@media screen and (min-width: 650px) {
    .address {
        font-size: 14px
    }
    .migrate-card-header {
        font-size: 16px;
    }
    .migrate-button-wrapper-mobile {
        display: none;
    }
}

.card-wrapper {
    width: 100%;
    border-radius: 12px;
    padding: 5% 5%;
    overflow-wrap: break-word;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.card-wrapper p {
    margin-bottom: 0;
}

.solid {
    border: 1px solid var(--color-transparent);
}

.dashed {
    border-radius: 4px;
    border: 1px dashed var(--color-transparent);
}

.migrate-card-header {
    display: flex;
    font-family: Tyros Pro;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.migrate-card-header {
    display: flex;
    font-family: Tyros Pro;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.token-logo {
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

.button-migrate {
    width: 100%;
    font-size: 13px;
    height: 48px;
}

.date-container {
    display: flex;
    justify-content: space-between;
}

.flexible {
    flex-grow: 1;
}

.title-description-wrapper {
    display: flex;
    flex-direction: column;
}

.migrate-contract-address-box {
    display: flex;
    justify-content: space-between;
}
