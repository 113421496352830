@media screen and (min-width: 750px) {
    .popup-provider-box {
        width: 376px;
    }
}

@media screen and (max-width: 750px) {
    .popup-provider-box {
        width: 294px;
    }
}

.popup-provider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.popup-provider-box {
    height: 76px;
    border: 1px solid var(--color-transparent);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.popup-provider-box:hover {
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
}

.popup-provider-bottom-text {
    margin-top: 8px;
    font-family: "Heebo";
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.popup-provider-bottom-text a {
    text-decoration: underline;
    color: inherit;
}