/* mobile css */
@media screen and (max-width: 750px) {
  .stake-validators-mobile-container {
    width: 100%;
  }

  .delegation-warning-mobile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 14px 16px;
    background: #FCF1EF;
    border: 1px solid #DC6D5E;
    border-radius: 12px;
    color: #DC6D5E;
    margin-top: 18px;
  }

  .delegation-success-mobile {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 14px 16px;
    background: #EFFAEF;
    border: 1px solid #3C9D40;
    border-radius: 12px;
    color: #3C9D40;
    margin-top: 18px;
    justify-content: left;
  }
}

.stake-validators-table-wrapper {
  width: min(100%, 1242px);
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 16px;
  border: 1px solid var(--color-transparent);
  border-radius: 12px;

  align-self: stretch;
}

.stake-validators-table-wrapper table {
  width: min(100%, 1242px);
  margin-bottom: 0;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 16px;
  border: none;
  margin: 0 auto;
  color: var(--color-primary);
  background: var(--color-bg-primary);
  table-layout: fixed;
}

.stake-validators-table-wrapper th {
  border-bottom: none;
  font-family: "Tyros Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  height: 52px;
  padding: 0;
}

.stake-validators-table-wrapper td {
  padding: 0;
  height: 72px;
  line-height: 18px;
}

.stake-validators-table-wrapper th:nth-last-child(1) {
  border-radius: 0px 12px 0px 0px;
}

.stake-validators-table-wrapper td:nth-child(5) {
  font-weight: 700;
}

/* column widths */
.stake-validators-table-wrapper th:nth-child(1) {
  width: 39px;
  border-radius: 12px 0px 0px 0px;
}

.stake-validators-table-wrapper th:nth-child(2) {
  width: 228px;
}

.stake-validators-table-wrapper th:nth-child(3) {
  width: 73px;
}

.stake-validators-table-wrapper th:nth-child(4) {
  width: 165px;
}

.stake-validators-table-wrapper th:nth-child(5) {
  width: 170px;
  white-space: nowrap;
}

.stake-validators-table-wrapper th:nth-child(6) {
  width: 110px;
}

.stake-validators-table-wrapper th:nth-child(7) {
  width: 110px;
}

.stake-validators-table-wrapper th:nth-child(8) {
  width: 183px;
}

.stake-validators-table-wrapper th:nth-child(9) {
  width: 130px;
  position: sticky;
  right: 0;
}

.stake-validators-table-row .stake-validators-table-delegate-wrapper {
  position: sticky;
  right: 0;
  width: 130px;
  height: 72px;
  padding-left: 32px;
  padding-right: 32px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--color-bg-primary);
  transition: box-shadow 0.2s ease-in-out;
}

.stake-validators-table-row:hover .stake-validators-table-delegate-wrapper {
  background-color: var(--color-bg-hover);
}

@media screen and (max-width: 1260px) {
  .stake-validators-table-row .stake-validators-table-delegate-wrapper {
    box-shadow: -17px 0px 21px 0px rgba(4, 23, 30, 0.03);
  }
}

.stake-validators-table-poolsize-header {
  display: flex;
  float: right;
  cursor: pointer;
}

.stake-validators-table-cumulative {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.stake-validators-table-cumulative-chart {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--color-bg-disabled);
}

.stake-validators-table-cumulative-chart> :nth-child(1) {
  background: #f1f7fc;
  height: 100%;
}

.stake-validators-table-cumulative-chart> :nth-child(2) {
  background: var(--color-branding-secondary);
  height: 100%;
}

.stake-validators-table-cumulative-text {
  position: absolute;
  display: block;
  text-align: right;
  width: 120px;
  padding-right: 23px;
}

.stake-validators-table-status {
  display: flex;
  align-items: center;
}

.stake-validators-table-status-signal {
  position: relative;
}

.stake-validators-table-status-signal:hover>.stake-validators-table-status-hover {
  display: block;
}

.stake-validators-table-status-hover {
  position: absolute;
  z-index: 10;

  display: none;
  border: 1px solid var(--color-branding-secondary);
  color: var(--color-branding-secondary);
  background: var(--color-bg-primary);
  border-radius: 12px;
  width: 214px;
  padding: 10px 16px 6px;
  font-size: 12px;
  left: 8px;
  top: -8px;
  transform: translate(-50%, -100%);
}

.stake-row-button:hover {
  color: var(--color-branding-secondary);
  cursor: pointer;
}

.stake-validators-table-slash-icon {
  position: relative;
  height: 18px;
  margin-right: 6px;
  width: 18px;
}


.stake-validators-table-slash-hover {
  position: absolute;
  z-index: 10;

  border: 1px solid var(--color-error);
  color: var(--color-error);
  background: var(--color-bg-primary);
  border-radius: 12px;
  width: 214px;
  padding: 10px 16px 6px;
  font-size: 12px;
  left: 8px;
  top: -8px;
  transform: translate(-50%, -60%);
}