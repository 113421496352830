.chain-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    height: 24px;
    font-family: Tyros Pro;
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px 5px 0;
    border-radius: 8px;
}

.carbon-card {
    border: 1px solid #4FC1C0;
    color: #4FC1C0;
}

.zil-card {
    border: 1px solid #29CCC4;
    color: #29CCC4;
}

.bsc-card {
    border: 1px solid #E4910A;
    color: #E4910A;
}

.eth-card {
    border: 1px solid #8094EB;
    color: #8094EB;
}

.arbitrum-card {
    border: 1px solid #28A0F0;
    color: #28A0F0;
}

.neo-card {
    border: 1px solid #05C185;
    color: #05C185;
}

.osmosis-card {
    border: 1px solid #8E01BA;
    color: #8E01BA;
}
