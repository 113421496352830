@media screen and (max-width: 750px) {
    .banner-front-text {
        display: none;
    }
}

.banner {
    background: #04171E;
    padding: 8px 67px 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-bg-primary);
    white-space: initial;
}

.banner-hidden {
    display: none;
}

.banner p {
    margin-bottom: 0;
}

.banner a {
    text-decoration: underline;
    color: inherit;
}

.banner-cross {
    position: absolute;
    right: 18px;
    cursor: pointer;
}
