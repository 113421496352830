.token-reward-container {
    border-top: 1px solid #BDCBD1;
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.token-reward-amount-wrapper {
    display: flex;
}

.token-reward-amount-wrapper > * {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Heebo';
    margin: 0px;
}

.reward-table-text-secondary {
    color: var(--color-text-secondary);
}