@media screen and (max-width: 1030px) {
  .stake-info-card-active {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 344px;
  }
  .stake-info-card-inactive {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 344px;
  }
  .stake-info-card-2-section {
    width: 100%;
  }
  .stake-divider {
    border-bottom: 1px solid var(--color-transparent);
    width: 100%;
  }
  .stake-button {
    height: 48px;
    pointer-events: auto;
    font-size: 14px;
    width: 100%;
  }
  .stake-info-card-active .stake-info-title {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 20px;
  }
  .stake-info-card-inactive .stake-info-title {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 20px;
  }
  .stake-info-card-active .stake-info-description-primary {
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }
  .stake-info-card-active .stake-info-description-secondary {
    line-height: 18px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    height: 18px
  }
  .stake-info-card-inactive .stake-info-description-primary {
    font-size: 16px;
    line-height: 20px;
    height: 20px
  }
  .stake-info-card-inactive .stake-info-description-secondary {
    line-height: 18px;
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    height: 18px;
  }
  .stake-info-card-2-section {
    padding: 20px 16px;
    height: 172px;
  }
}


@media screen and (min-width: 1031px) {
  .stake-info-card-2 {
    width: 764px;
    height: 228px;
  }
  .stake-info-card-2-section {
    width: 50%;
  }
  .stake-divider {
    border-right: 1px solid var(--color-transparent);
    height: 100%;
  }

  .stake-button {
    height: 48px;
    pointer-events: auto;
    border: 0;
    font-size: 14px;
    margin-right: -0.5px;
    width: 180px;
  }
  .stake-info-card-active .stake-info-title {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 20px;
  }
  
  .stake-info-card-inactive .stake-info-title {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 20px;
  }
  .stake-info-card-active .stake-info-description-primary {
    font-size: 28px;
    line-height: 36px;
    height: 36px;
  }
  .stake-info-card-active .stake-info-description-secondary {
    line-height: 26px;
    margin-top: 6px;
    font-weight: 400;
    font-size: 20px;
    height: 26px;
  }
  .stake-info-card-inactive .stake-info-description-primary {
    font-size: 28px;
    line-height: 36px;
    height: 36px;
  }
  .stake-info-card-inactive .stake-info-description-secondary {
    line-height: 26px;
    margin-top: 6px;
    font-weight: 400;
    font-size: 20px;
    height: 26px;
  }

  .stake-info-card-2-section {
    padding: 26px 24px;
  }
}

.stake-info-card-2 {
  display: flex;
}

.stake-info-card-2-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stake-info-card-active .stake-info-title {
  color: var(--color-text-secondary);
}

.stake-info-card-active .stake-info-description-secondary {
  color: var(--color-text-secondary);
}

.stake-info-card-inactive .stake-info-title {
  color: var(--color-text-disabled);
}

.stake-info-card-inactive .stake-info-description-primary {
  color: var(--color-text-disabled);
}

.stake-info-card-inactive .stake-info-description-secondary {
  color: var(--color-text-disabled);
}

.stake-claim-rewards {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  border: 1px solid var(--color-transparent);
  display: flex;
  flex-direction: row-reverse;
  pointer-events: none;
  margin-bottom: 0;
}

.stake-claim-rewards > p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stake-claim-rewards:hover {
  border: 1px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(87.57deg, #3a78b1 15.39%, #4fc1c0 69.96%, #dafff4 132.29%)
      border-box;
}

.stake-button:disabled + p {
  color: var(--color-text-disabled);
}

.stake-table-button-theme {
  padding: 0;
  min-width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-family: Tyros Pro;
  font-weight: 600;
  border-radius: 12px;
  font-size: 14px;
  border: none;
  margin-bottom: 0;
}
