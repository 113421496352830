@media screen and (min-width: 750px) {
    .bridge-wallet-utils {
        flex-direction: row;
        gap: 16px;
    }

    .bridge-wallet-disconnect-button {
        width: 50%;
    }

    .bridge-wallet-address {
        padding: 12px 16px;
        width: 50%;
    }
}

@media screen and (max-width: 750px) {
    .bridge-wallet-utils {
        flex-direction: column;
        gap: 8px;
    }

    .bridge-wallet-disconnect-button {
        width: 126px;
    }

    .bridge-wallet-address {
        padding: 12px;
        width: 168px;
    }
}

.bridge-wallet-disconnect {
    width: 100%;
    height: 48px;
    border-radius: 12px;
    border: 1px solid var(--color-transparent);
    display: flex;
    flex-direction: row-reverse;
    pointer-events: none;
}

.bridge-wallet-disconnect:hover {
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
}

.bridge-wallet-address {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
}

.bridge-wallet-disconnect-button {
    height: 100%;
    border: 0;
    font-size: 14px;
    margin-right: -0.5px;
    pointer-events: auto;
}

.bridge-wallet-utils {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Heebo;
    font-weight: 400;
    font-size: 16px;
    color: var(--color-text-secondary);
    margin: 16px 0px;
    white-space: nowrap;
}

.bridge-wallet-utils-wrapper * {
    display: flex;
}

.bridge-wallet-utils-wrapper a {
    color: inherit;
}

.bridge-wallet-utils-item {
    cursor: pointer;
    gap: 6px;
    align-items: center;
}

.bridge-wallet-info {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--color-transparent);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bridge-wallet-info-header {
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-secondary);
}

.bridge-wallet-info-main {
    display: flex;
    gap: 8px;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
}

.bridge-wallet-info-secondary {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-text-secondary);
}
