@media screen and (max-width: 446px) {
    .node-form-info {
        width: 270px;
    }
}

@media screen and (min-width: 445px) {
    .node-form-info {
        width: 352px
    }
}

.node-form-wrapper {
    width: max-content;
    display: flex;
    flex-direction: column;
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary)
}

.node-form-back-button {
    display: flex;
    align-items: center;
    width: 69px;
    gap: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
}

.node-form-back-button:hover {
    color: var(--color-branding-secondary);
}

.node-form-info {
    margin-top: 16px;
}

.node-form-info>div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.node-form-input {
    border-radius: 12px;
}

.node-form-input [type="text"] {
    border-radius: 12px;
    height: 48px;
    width: 100%;
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    padding: 14px 16px;
    margin-bottom: 0px;
    border: none;
    /* Firefox */
    -moz-appearance: textfield;
    appearance: none;
}

.node-form-buttons {
    display: flex;
    gap: 15px
}