@media screen and (max-width: 750px) {
    .banner-inner-wrapper {
        width: 92%;
        margin-left: -100px;
        color: white;
        padding: 15px 15px;
        background-image: url('assets/Banner.png');
        background-size: cover;
        height: 215px;
    }
    .banner-wrapper {
        height: 350px;
    }
}

@media screen and (min-width: 750px) {
    .banner-inner-wrapper {
        max-width: var(--max-screen-width);
        width: 84%;
        margin-left: -200px;
        height: 215px;
        color: white;
        padding: 25px 40px;
        background-image: url('assets/Banner.png');
        background-size: 100% 100%;
    }
    .migrate-now-wrapper {
        display: flex;
        justify-content: flex-end;
    }
    .banner-wrapper {
        height: 400px;
    }
}

.banner-wrapper {
    display: flex;
    justify-content: center;
}

.show {
    margin-left: 0;
    opacity: 1;
    transition: 2s linear 1s;
}

.banner-inner-wrapper {
    background-repeat: no-repeat;
    background-position: bottom;
    opacity: 0;
    border-radius: 20px;
}

.white {
    color: white;
}