/* For Laptop */

@media screen and (min-width: 900px) {
    .header-title {
        font-size: 24px;
    }
    .header-routes {
        width: 500px;
        font-size: 15px;
        display: flex;
        justify-content: space-around;
        margin-right: 10px;
        height: 100%;
    }
    .header-icon-set {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 120px;
    }
    .header-drop-down-button {
        display: none;
    }
    .header-drop-down-menu {
        display: none;
    }
    .header-sections {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    .header-icon {
        width: 200px;
    }
}


/* For Mobile */

@media screen and (max-width: 900px) {
    .header-sections {
        display: none !important;
    }
    .header-title {
        font-size: 21px;
    }
    .header-drop-down-button {
        flex-direction: row;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        margin-right: 2vw;
        height: 24px;
        width: 24px;
    }
    .header-drop-down-menu {
        display: flex;
        padding: 5% 15%;
        height: 400px;
        position: fixed;
        background-color: #04171E;
        color: rgb(235, 230, 230);
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        z-index: 2;
        width: 100vw;
        min-width: 300px;
        margin-top: -400px;
    }
    .header-icon {
        width: 230px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1600px) {
    .header-sections {
        padding-right: 20px;
    }
}

.header-wrapper {
    position: fixed;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-background-wrapper {
    background-color: white;
    border-bottom: 1px solid var(--color-transparent);
    width: 100%
}

.header-inner-wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #335F6F;
    font-family: Tyros Pro;
    justify-content: space-between;
    overflow-x: hidden;
    width: 100%;
    height: 70px;
    max-width: var(--max-screen-width);
}

.header-title {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
}

.header-logo-container {
    align-items: center;
    display: flex;
}

.header-routes > a {
    height: 100%;
    display: flex;
    align-items: center;
}

.rotate {
    transform: rotate(90deg);
    transition: all 0.4s ease;
}

.collapse {
    transition: margin-top 0.3s ease;
    margin-top: 70px;
}

.uncollapse {
    transition: margin-top 0.3s ease;
}

.header-menu-banner-margin {
    margin-top: 110px;
}

.link {
    color: var(--color-primary);
}

.link-active,
.link-active:hover,
.link-active:focus {
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
    font-weight: 600;
}

.header-link {
    color: white;
    font-weight: 400;
    font-family: Tyros pro;
}

.header-link:hover {
    color: #3A78B1
}

.icon-set-mobile {
    width: 100px;
}
