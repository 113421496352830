.search-bar {
    border: 1px solid var(--color-transparent);
    height: 48px;
    border-radius: 12px;
    background: var(--color-bg-primary);
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
    width: 100%;
    gap: 6px;
    padding-left: 16px;
    margin-bottom: 0;
}

.search-bar [type="text"] {
    border: none;
    height: 100%;
    margin-bottom: 0;
    padding: 0;
    font-family: Tyros Pro;
    font-weight: 700;
    font-size: 14px;
}

.search-bar [type="text"]::placeholder {
    color: var(--color-text-secondary);
}

.search-bar [type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-text-secondary);
  }
  
.search-bar [type="text"]::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-text-secondary);
}
