@media screen and (max-width: 750px) {
    .explore-wrapper {
        background-size: 80% 40%;
        margin-top: 150px;
    }
    .explore-inner-wrapper {
        width: 92%;
        margin-left: -200px;
        opacity: 0;
    }
}

@media screen and (min-width: 750px) {
    .explore-wrapper {
        margin-top: 100px;
    }
    .explore-inner-wrapper {
        width: 84%;
        max-width: var(--max-screen-width);
        margin-left: -200px;
        opacity: 0;
    }
}

.explore-wrapper {
    width: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
    justify-content: center;
    display: flex;
    padding-bottom: 200px;
}

.show {
    opacity: 1;
    transition: 2s;
    margin-left: 0px;
}