@media screen and (max-width: 750px) {
    .redelegate-card-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
        padding-top: 170px;
    }

    .redelegate-card {
        min-width: 342px;
        width: 342px;
        padding: 24px 16px;
    }

    .redelegate-card-back-button-wrapper {
        width: 342px;
    }

    .redelegate-card-header {
        flex-direction: column;
        line-height: 24px;
    }

    .redelegate-card-header > p {
        margin-bottom: 12px;
    }

    .validator-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        gap: 16px;
    }

    .validator-name-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
    }

    .validator-name-wrapper>p {
        font-size: 14px;
        font-family: Tyros Pro;
        font-weight: 700;
        margin-bottom: 12px;
    }

    .stake-input-wrapper {
        margin-top: 16px;
    }

    .validator-moniker {
        background-color: #f2f7f9;
        border-radius: 12px;
        color: var(--color-text-secondary);
        margin-top: 0;
        padding: 8px;
        padding-left: 16px;
    }

    .stake-balance-box {
        font-size: 12px;
    }
}

@media screen and (min-width: 750px) {
    .redelegate-card-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Token_Desktop_1.svg'), url('assets/Backgrounds/Migrate_Token_Desktop_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top, left bottom;
        padding-top: 170px;
    }

    .no-padding {
        transform: translateY(-70px)
    }

    .redelegate-card {
        width: 596px;
        padding: 40px 32px;
    }

    .redelegate-card-back-button-wrapper {
        width: 596px;
    }

    .active {
        width: 440px;
    }

    .redelegate-card-header {
        height: 48px;
        align-items: center;
    }

    .redelegate-card-header>p {
        margin-bottom: 0;
    }

    .validator-info-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
        margin-top: 24px;
    }

    .validator-name-wrapper {
        display: flex;
        flex-direction: column;
        width: 347px;
        margin-top: 0;
    }

    .validator-name-wrapper>p {
        font-size: 14px;
        font-family: Tyros Pro;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .stake-input-wrapper {
        margin-top: 24px;
    }

    .validator-moniker {
        background-color: #f2f7f9;
        border-radius: 12px;
        color: var(--color-text-secondary);
        margin-top: 0;
        padding: 8px;
        padding-left: 16px;
    }

    .stake-balance-box {
        font-size: 14px;
    }
}

.redelegate-card-wrapper {
    min-height: 100vh;
    min-width: 300px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    font-family: Tyros Pro;
    display: flex;
    align-items: center;
}

.redelegate-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: auto;
}

.redelegate-card-wrapper::-webkit-scrollbar {
    display: none;
}

.redelegate-card-header {
    font-family: Tyros Pro;
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
}

.redelegate-card-header button {
    font-size: 14px;
    font-weight: 700;
}

.redelegate-card {
    border-radius: 10px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 116px;
}

.redelegate-card-back-button {
    display: flex;
    align-items: center;
    width: 69px;
    gap: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
}

.redelegate-card-back-button:hover {
    color: var(--color-branding-secondary);
}

.validator-dropdown-wrapper>div {
    height: 48px;
}

.stake-input-wrapper {
    display: flex;
    flex-direction: column;
}

.stake-input-wrapper>p {
    font-size: 14px;
    font-family: Tyros Pro;
    font-weight: 700;
    margin-bottom: 8px;
}

.stake-input-wrapper>label {
    margin-top: 0;
}

:not(.stake-amount-disabled)>.stake-amount-input:hover,
:not(.stake-amount-disabled)>.active-border {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.stake-amount-input {
    border: 1px solid var(--color-transparent);
    height: 60px;
    border-radius: 12px;
    margin-top: 8px;
    display: flex;
}

.stake-amount-input [type="number"] {
    display: block;
    border: none;
    height: 100%;
    width: 90%;
    font-family: 'Tyros Pro';
    font-size: 20px;
    font-weight: 700;
    color: #0a3f52;
    margin-left: 8px;
    /* Firefox */
    -moz-appearance: textfield;
    appearance: none;
}

.stake-amount-input [type="number"]::-webkit-outer-spin-button,
.stake-amount-input [type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.stake-details {
    width: 100%;
    margin: 20px 0;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
}

.stake-details div {
    display: flex;
    justify-content: space-between;
}

.stake-details div:first-child {
    margin-bottom: 7px;
}

.stake-details div:nth-child(3) {
    color: var(--color-text-secondary);
    font-size: 14px;
}

.stake-amount,
.bridge-warning {
    width: 100%;
}

.stake-amount-disabled {
    opacity: 0.5;
}

#bottom-button {
    margin-top: 16px;
    width: 100%;
    font-size: 14px;
    height: 48px;
}

.stake-balance-box {
    color: var(--color-text-secondary);
    font-family: Heebo;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
}

.stake-balance {
    display: flex;
    justify-content: space-between;
    line-height: 18px;
    width: 100%;
    gap: 8px;
}

.input-error {
    color: var(--color-error);
    font-family: 'Heebo';
    font-weight: 400;
}

.stake-refresh-rotate {
    -webkit-animation: spin 1s ease-in-out;
    -moz-animation: spin 1s ease-in-out;
    animation: spin 1s ease-in-out;
}

.stake-refresh-pointer {
    cursor: pointer;
}

@-moz-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}