.stake-delegation-info {
    margin-top: 56px;
}

.delegations-cards-mobile-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background: #1770900D;
    margin: 40px -20px;
    padding: 16px 20px;
}

.delegations-card-mobile-tab-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
}

.delegations-card-tab-name {
    line-height: 24px;
    font-size: 20px;
}

.num-of-delegations {
    border-radius: 8px;
    background-color: rgb(231, 236, 238);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    width: 19px;
    height: 24px;
}