.stake-undelegating-wrapper {
    width: 100%;
}

.stake-undelegating-inner-wrapper {
    width: 100%;
}

.stake-undelegating-wrapper th {
    border-bottom: none;
    font-family: "Tyros Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 52px;
    padding: 0;
}

.stake-undelegating-wrapper td {
    padding: 0;
    height: 72px;
    line-height: 18px;
}

.stake-undelegating-wrapper th:nth-child(1) {
    border-radius: 12px 0px 0px 0px;
}

.stake-undelegating-wrapper th:nth-last-child(1) {
    border-radius: 0px 12px 0px 0px;
}

.stake-undelegating-wrapper table {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-transparent);
    border-radius: 12px;
    color: var(--color-primary);
    background: var(--color-bg-primary);
}

.stake-undelegating-wrapper td:nth-child(1) {
    width: 289px;
}

.stake-undelegating-wrapper td:nth-child(2) {
    width: 124px;
}

.stake-undelegating-wrapper td:nth-child(3) {
    width: 241px;
    font-weight: 400;
}

.stake-undelegating-wrapper td:nth-child(4) {
    width: 155px;
}

.stake-undelegating-wrapper td:nth-child(5) {
    width: 235px;
}

.stake-undelegating-wrapper td:nth-child(6) {
    width: auto;
}

.stake-undelegating-time-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
}

.stake-undelegating-time-bar {
    width: 140px;
    height: 8px;
    border-radius: 4px;
    background: var(--color-bg-disabled);
}

.stake-undelegating-time-left {
    height: 100%;
    background: linear-gradient(
        87.57deg,
        #3a78b1 15.39%,
        #4fc1c0 85.59%,
        #dafff4 132.29%
    );
    border-radius: 4px;
}
