.status-block {
    height: 24px;
    border: 1px solid;
    border-radius: 8px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    font-family: Tyros Pro;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    width: max-content;
}

.status-block p {
    margin-bottom: 0;
}
