.dots {
    display: flex;
    margin: 0 12px;
    align-items: center;
    height: 40px;
}

.dot1,
.dot2,
.dot3 {
    background: #fff;
    width: 6px;
    height: 6px;
    border: 1.5px solid var(--color-text-secondary);
    border-radius: 50%;
    margin: 5px;
}

.dot1 {
    animation: jumpfirst 2s linear infinite;
}

.dot2 {
    animation: jumpsecond 2s linear infinite;
}

.dot3 {
    animation: jumpthird 2s linear infinite;
}

@keyframes jumpfirst {
    33% {
        background: var(--color-primary);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes jumpsecond {
    50% {
        background: var(--color-primary);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}

@keyframes jumpthird {
    67% {
        background: var(--color-primary);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
    }
}
