.notification-img-wrapper {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.warning-message {
  font-size: 14px;
  display: flex;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 14px 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-family: Heebo;
}
.warning-message > div {
  margin-top: 8px;
}

.notification-max-width {
  word-break: break-word;
}

.warning {
  color: #d9aa03;
  background-color: rgba(217, 170, 3, 0.08);
  font-family: Heebo;
}

.success {
  color: #3c9d40;
  font-family: Heebo;
  background-color: rgba(87, 204, 91, 0.1);
}

.neutral {
  color: #3a78b1;
  background-color: rgba(58, 120, 177, 0.1);
  font-family: Heebo;
}

.error {
  color: #dc6d5e;
  font-family: Heebo;
  background-color: rgba(220, 109, 94, 0.1);
}

.list-wrapper {
  list-style-position: outside;
  padding: 8px 0 0 16px;
  margin-bottom: 0px;
}

li {
  font-family: Heebo;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}
