.exchanges-table-wrapper {
    width: 100%;
    overflow: auto;
}

.exchanges-table-inner-wrapper {
    overflow: auto;
}

.exchanges-table-header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: var(--color-bg-disabled);
}

.exchange-header-button {
    cursor: pointer;
    width: fit-content;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: flex;
    align-items: center;
}

th {
    border-bottom: none;
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    /* Text/Primary */
    height: 52px;
}

th:nth-child(1) {
    border-radius: 12px 0px 0px 0px;
}

th:nth-last-child(1) {
    border-radius: 0px 12px 0px 0px;
}

table {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-transparent);
    width: 1160px;
    border-radius: 12px;
    color: var(--color-primary);
}

tbody tr td {
    height: 112px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-bottom: none;
    border-top: 1px solid var(--color-transparent);
}

tbody tr:hover td {
    background: var(--color-bg-hover)
}

tbody tr:nth-last-child(1):hover td:nth-last-child(1) {
    border-radius: 0px 0px 12px 0px;
}

tbody tr:nth-last-child(1):hover td:nth-child(1) {
    border-radius: 0px 0px 0px 12px;
}

.exchange-card {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Heebo';
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
}

.chain {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 250px;
}

.dark {
    color: white;
    background: var(--color-primary);
}

.light {
    color: #335F6F;
    background: var(--color-bg-disabled);
}

.exchanges-table-wrapper td:nth-child(1) {
    width: 180px;
}

.exchanges-table-wrapper td:nth-child(2) {
    width: 130px;
    text-align: right;
}

.exchanges-table-wrapper td:nth-child(3) {
    width: 140px;
    text-align: right;
    font-weight: 700;
    font-size: 15px;
}

.exchanges-table-wrapper td:nth-child(4) {
    width: 130px;
    text-align: right;
}

.exchanges-table-wrapper th:nth-child(5) {
    padding-left: 60px;
}

.exchanges-table-wrapper td:nth-child(5) {
    width: 190px;
    padding-left: 60px;
}

.exchanges-table-wrapper td:nth-child(6) {
    width: 390px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 112px;
}

.dropdown-icon {
    vertical-align: middle;
}

.more-info {
    display: flex;
    align-items: center;
    gap: 2px;
    width: 80px;
    cursor: pointer;
    font-weight: 700;
    font-size: 15px;
    position: relative;
}

.more-info a {
    color: var(--color-primary);
}
