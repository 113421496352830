.signal {
    display: flex;
    gap: 4px;
}

.signal-bar {
    height: 16px;
    border-radius: 100px;
    min-width: 3px;
}
