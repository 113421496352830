.wallet-icon {
    height: 32px;
    width: 32px;
}

select {
    color: var(--color-primary);
    font-size: 16px;
    height: 45px;
    margin-top: 10px;
    font-family: Source Sans Pro;
}

select:focus {
    border: 1px solid var(--color-transparent);
}

.tx-hash-container {
    display: flex;
    width: 100%;
    margin-top: 14px;
}

.tx-hash {
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    white-space: nowrap;
    margin-left: 8px;
}

@media screen and (max-width: 750px) {
    .send-receive {
        flex-direction: column;
    }
    .migrateCard-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
    }
    .token-migrate-card {
        min-width: 342px;
        width: 342px;
        margin: 60px 30px;
        padding: 20px 20px;
    }
    .migrate-back-button-wrapper {
        width: 342px;
    }
    .migration-icon {
        transform: rotate(90deg);
        width: 50px;
        height: 50px;
        padding: 10px;
        align-self: center;
    }
    .button-migrate-token {
        width: 180px;
        font-size: 13px;
        height: 44px;
    }
    .demex-screenshot {
        width: 100%;
        margin-left: 0%;
        margin-bottom: 8px;
        text-align: center;
    }
    .migrateCard-header {
        display: block;
    }
    .migrateCard-header > p {
        margin-bottom: 16px;
    }
    .token-balance-wrapper {
        margin-bottom: 16px;
    }
    .notification-wrapper {
        margin-top: 16px;
    }
}

@media screen and (min-width: 750px) {
    .send-receive {
        flex-direction: row
    }
    .migrateCard-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Token_Desktop_1.svg'), url('assets/Backgrounds/Migrate_Token_Desktop_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top, left bottom;
    }
    .token-migrate-card {
        width: 638px;
        padding: 30px 30px;
    }
    .migrate-back-button-wrapper {
        width: 638px;
    }
    .migration-icon {
        padding-top: 30px;
        padding-left: 18px;
        padding-right: 18px;
    }
    .button-migrate-token {
        width: 260px;
        font-size: 13px;
        height: 44px;
    }
    .demex-screenshot {
        width: 70%;
        margin-left: 15%;
        margin-bottom: 5px;
        object-fit: fill;
    }
    .migrateCard-header {
        justify-content: space-between;
        flex-direction: row;
        height: 48px;
        display: flex;
        align-items: center;
    }
    .migrateCard-header > p {
        margin-bottom: 0;
    }
    .token-balance-wrapper {
        margin-bottom: 24px;
    }
    .notification-wrapper {
        margin-top: 24px;
    }
}

.migrateCard-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 110px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.migrateCard-wrapper::-webkit-scrollbar {
    display: none;
}

.migrateCard-header {
    font-family: Tyros Pro;
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
    margin-bottom: 16px;
}

.migrateCard-header button {
    font-size: 14px;
    font-weight: 700;
}

.token-migrate-card {
    border-radius: 10px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    margin-top: 110px;
    height: fit-content;
    margin-bottom: 116px;
}

.migrate-back-button-wrapper {
    position: absolute;
    top: 185px;
}

.migrate-back-button {
    display: flex;
    align-items: center;
    width: 69px;
    gap: 6px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
}

.migrate-back-button:hover {
    color: var(--color-branding-secondary);
}

.migrate-wallet-address-wrapper {
    height: 48px;
    width: 180px;
}

.migrate-wallet-address-holder {
    background-color: var(--color-bg-primary);
    border: 1px solid var(--color-transparent);
    font-weight: 700;
    border-radius: 12px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    overflow: hidden;
}

.migrate-wallet-address-holder:hover {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
    cursor: pointer;
}

.select-token-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.token-balance-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid #BDCBD1;
    border-radius: 16px;
    padding: 24px;
}

.token-balance-disabled {
    opacity: 0.5;
}

.token-balance-header {
    font-family: Tyros Pro;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-secondary);
}

.token-balance-main {
    margin-top: 6px;
    margin-bottom: 4px;
    display: flex;
    gap: 8px;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
}

.token-balance-secondary {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-text-secondary);
}

.token-migration-token-box {
    border-radius: 12px;
    padding-left: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 8px;
    display: block;
}

.send-receive {
    display: flex;
}

.send-receive-column {
    width: 100%;
}

.notification-wrapper {
    margin-bottom: 16px;
}

.token-migration-headers {
    font-size: 14px;
    font-family: Tyros Pro;
    font-weight: 700;
}

.migrate-on-demex {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: normal;
    margin-top: 15px;
    color: var(--color-primary);
    display: flex;
    flex-direction: column;
}

.demex-caption {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
    color: var(--color-primary);
}

.migrate-img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}

.migrate-bottom-button {
    margin-top: 16px;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    height: 48px;
}
