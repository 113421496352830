.tooltip {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
}

.tooltip:hover .tooltip-more-info-icon {
    background: #F3F6F7;
    transition: all 0.2s;
    transition-timing-function: ease-in;
}

.tooltip-more-info-icon {
    cursor: pointer;
    height: 24px;
    padding: 3px;
    border-radius: 50%;
}

.tooltip:hover > .tooltip-hover-box {
    display: flex !important;
}

.tooltip-hover-box {
    /* BG/Primary */
    background: var(--color-bg-primary);
    /* Branding/Secondary */
    border: 1px solid #3A78B1;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #3A78B1;
    padding: 8px 16px;
    width: 214px;
    display: none !important;
    left: 12px;
    bottom: 32px;
    position: absolute;
    flex-direction: column;
    transform: translateX(-50%);
    z-index: 1;
}

.tooltip-hover-box:hover {
    display: flex;
}
