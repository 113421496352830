@media screen and (min-width: 750px) {
    .bridge-history-cards-wrapper {
        display: none;
    }

    .bridge-history-wrapper {
        height: 1410px;
    }
}

@media screen and (max-width: 750px) {
    .bridge-history-table-wrapper {
        display: none !important;
    }

    .bridge-history-tabs {
        padding-left: 16px;
    }
}

@media screen and (max-width: 750px) {
    .mobile-hidden {
        display: none !important;
    }
}

.bridge-history-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.bridge-history-tabs-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 130px;
    padding-bottom: 12px;
}

.bridge-history-tabs {
    border-bottom: 1px solid var(--color-transparent);
    width: 900px;
    display: flex;
    height: 40px;
}

.bridge-history-tab-button {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: bold;
    margin-right: 16px;
    opacity: 0.8;
    height: 100%;
    cursor: pointer;
    width: auto;
}

.bridge-history-tab-button.active {
    opacity: 1;
    border-bottom: 2px solid var(--color-primary);
}

.bridge-history-table-wrapper {
    overflow: auto;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bridge-history-cards-wrapper {
    width: 100%;
    font-family: 'Heebo';
    font-weight: 400px;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    overflow: auto;
    margin-bottom: 19px;
}

.bridge-history-pagination-wrapper {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-primary);
    margin-bottom: 65px;
}

.bridge-history-pagination-wrapper :not(.bridge-history-pagination-dots) {
    cursor: pointer;
}

.bridge-history-pagination-active {
    font-weight: 700;
}