/*source sans*/

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400&family=Syne&display=swap");

/*Heebo*/

@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
@font-face {
  font-family: Tyros Pro;
  src: url("assets/Fonts/TyrosPro-Regular.ttf");
  font-display: block;
}

:root {
  --max-screen-width: 1400px;
  --color-primary: #0a3f52;
  --color-text-secondary: #6a8a96;
  --color-text-disabled: #94abb4;
  --color-bg-primary: #fefefe;
  --color-bg-disabled: #e7ecee;
  --color-transparent: #bdcbd1;
  --color-bg-hover: #f3f6f7;
  --color-error: #dc6d5e;
  --color-branding-secondary: #3a78b1;
}

/* For Mobile */

@media screen and (max-width: 650px) {
  .section-header {
    font-size: 32px;
    line-height: 30px;
  }
  .section-text {
    font-size: 15px;
  }
  .section-subheader {
    font-size: 17px;
  }
  .section-subpoint-header {
    font-size: 18px;
  }
  .button-text {
    font-size: 16px;
  }
  .title-image-holder {
    height: 25px;
  }
  .SWTH {
    height: 24px;
  }
  .welcome-header {
    font-size: 35px;
  }
  .waving-hand {
    width: 45px;
  }
  .section-header-mobile {
    max-width: 240px;
  }
}

/* For Tablets */

@media screen and (min-width: 650px) and (max-width: 1200px) {
  .section-header {
    font-size: 32px;
  }
  .section-text {
    font-size: 15px;
  }
  .section-subpoint-header {
    font-size: 24px;
  }
  .section-subheader {
    font-size: 18px;
  }
  .title-image-holder {
    height: 32px;
  }
  .button-text {
    font-size: 18px;
  }
  .SWTH {
    height: 26px;
  }
  .welcome-header {
    font-size: 42px;
  }
  .waving-hand {
    width: 50px;
  }
}

/* For Laptop */

@media screen and (min-width: 1200px) {
  .section-header {
    font-size: 40px;
  }
  .section-subpoint-header {
    font-size: 32px;
  }
  .section-text {
    font-size: 18px;
  }
  .section-subheader {
    font-size: 24px;
  }
  .title-image-holder {
    height: 40px;
  }
  .button {
    width: 150px;
  }
  .button-text {
    font-size: 20px;
  }
  .padding-10 {
    padding: 0 10%;
  }
  .SWTH {
    height: 28px;
  }
  .welcome-header {
    font-size: 65px;
  }
  .waving-hand {
    width: 55px;
  }
}

.waving-hand {
  vertical-align: middle;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Syne",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 375px ;
}

.section-header {
  font-family: Tyros Pro;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  width: fit-content;
}

.section-subheader {
  font-family: Tyros Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
}

.section-subpoint-header {
  font-family: Tyros Pro;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
}

.section-text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.welcome-header {
  font-weight: 700;
  font-family: Tyros Pro;
  letter-spacing: 2px;
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.bolded-400 {
  font-weight: 400;
  font-family: Tyros Pro;
}

.bolded-500 {
  font-weight: 500;
  font-family: Tyros Pro;
}

.bolded-600 {
  font-weight: 600;
  font-family: Tyros Pro;
}

.bolded-700 {
  font-weight: 700;
  font-family: Tyros Pro;
}

.align-row {
  display: flex;
  flex-direction: row;
}

.centralize-vertical {
  display: flex;
  align-items: center;
}

.read-only {
  background: rgba(23, 112, 145, 0.05);
}

.vertical-divider {
  border-right: 1px solid var(--color-transparent);
  height: 100%;
}

.dull-link:visited,
.dull-link:hover,
.dull-link:link {
  color: inherit;
}

.button-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-family: Tyros Pro;
  font-weight: 600;
  border-radius: 12px;
  border: none;
  /* width: clamp(100px, 30vw, 200px); */
  margin-bottom: 0;
}

.button-theme-primary {
  background-color: rgba(10, 63, 82, 1);
}

.button-theme-primary:hover {
  background: linear-gradient(to right, #3a78b1, #4fc1c0, #dafff4);
}

.button-theme-primary:focus,
.button-theme-primary:active {
  background-color: rgba(10, 63, 82, 1);
}

.button-theme-secondary {
  background-color: transparent;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.button-theme-secondary:hover {
  color: inherit;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(87.57deg, #3a78b1 15.39%, #4fc1c0 69.96%, #dafff4 132.29%)
      border-box;
  border: 1px solid transparent;
}

button:disabled,
button:disabled:focus,
button:disabled:hover {
  background: var(--color-bg-disabled);
  color: var(--color-text-disabled);
  pointer-events: none;
  font-weight: bold;
}

.theme-color {
  color: var(--color-primary);
}

.button-text {
  font-family: Tyros Pro;
  font-weight: 700;
  color: rgba(10, 63, 82, 1);
  width: fit-content;
}

.link {
  cursor: pointer;
  width: fit-content;
}

.link:hover {
  color: var(--color-branding-secondary);
}

.hover-underline-blue:hover {
  background-image: linear-gradient(to right, #3a78b1 100%, transparent 100%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 65% 2px;
  color: var(--color-branding-secondary);
}

.hover-underline-blue:hover > .SWTH {
  filter: invert(41%) sepia(68%) saturate(522%) hue-rotate(167deg)
    brightness(70%) contrast(70%);
}

.hover-underline-white:hover {
  background-image: linear-gradient(to right, white 100%, transparent 100%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 65% 2px;
  color: white;
}

.SWTH {
  vertical-align: middle;
}

.title-image-holder {
  vertical-align: middle;
}

.popup-screen {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reversed {
  transform: rotate(180deg);
}
