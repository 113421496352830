.dropdown {
    box-sizing: border-box;
    font-family: Tyros Pro;
    color: var(--color-text-secondary);
    font-weight: 700;
    font-size: 14px;
    position: relative;
}

.dropdown-header {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 12px 12px;
    border-radius: 12px;
    width: 100%;
    border: 1px solid var(--color-transparent);
    height: 100%;
}

.dropdown-list-wrapper {
    position: absolute;
    top: 56px;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid var(--color-transparent);
}

.dropdown-list {
    box-shadow: 0px 4px 12px var(--color-bg-disabled);
    background: var(--color-bg-primary);
    max-height: 240px;
    overflow: auto;
}

.dropdown-hover {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.dropdown-hover {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.dropdown-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-list .dropdown-list-item {
    padding: 12px 14px;
}

.dropdown-list .dropdown-list-item:hover {
    background-color: var(--color-bg-hover);
    color: var(--color-primary)
}

.dropdown-list-item-img {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.dropdown-list-item-img:first-child {
    transform: scale(1.2);
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dropdown-tag {
    font-size: 8px;
    font-weight: bolder;
    background-color: #f2f7f9;
    padding: 0.5px 4px;
    border-radius: 4px;
}


.dropdown-icon {
    vertical-align: middle;
}

.down {
    transform: rotate(180deg);
}
