.bridge-history-card {
    width: 342px;
    height: 312px;
    background: var(--color-bg-primary);
    border: 1px solid var(--color-transparent);
    border-radius: 20px;
    box-sizing: border-box;
    margin: auto;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
