.avatar {
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: #C4C4C4;
    align-items: center;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.avatar-name {
    display: flex;
    gap: 4px;
    color: inherit;
}

.avatar-name > p {
    margin: auto;
}

.avatar-name:hover {
    color: var(--color-branding-secondary);
}

.avatar-icon {
    margin: auto;
}