.bridge-table-row td:nth-child(1) {
    padding: 17px 0px 17px 25px;
}

.bridge-table-row td * {
    margin-bottom: 0;
}

.bridge-table-row td:nth-child(2) {
    padding: 27px 0;
}

.bridge-table-row td:nth-child(3) {
    font-weight: 400;
    padding-left: 0;
}

.bridge-table-row td:nth-child(4) {
    text-align: right;
    padding-right: 0;
    padding-left: 0;
}

.bridge-table-row td:nth-child(5) {
    text-align: right;
    padding-left: 0;
    padding-right: 0;
}

.bridge-table-row td:nth-child(6) {
    padding-left: 46px;
    display: table-cell;
}

.bridge-table-row td>div:first-child {
    display: flex;
    align-items: center;
}

.bridge-table-hash {
    margin-left: 8px;
    margin-right: 4px;
}

.bridge-table-disabled * {
    opacity: 0.5;
    color: #94ABB4;
}

.bridge-table-disabled span {
    opacity: 1;
}

.bridge-table-text-secondary {
    color: var(--color-text-secondary);
    font-size: 12px;
}
