/* imagine a max-width: 650px for this */
.stakePage-wrapper {
  background-image: url("assets/Backgrounds/Migrate_Mobile_1.svg"),
    url("assets/Backgrounds/Migrate_Mobile_2.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right top 70px, left bottom;
}

@media screen and (min-width: 650px) {
  .stakePage-wrapper {
    background-image: url("assets/Backgrounds/Stake_Desktop_1.png"),
      url("assets/Backgrounds/Stake_Desktop_2.png");
    background-repeat: no-repeat;
    background-position: right top 70px, left center;
  }
}

.stakePage-wrapper {
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 110px;
  padding-bottom: 123px;
  font-family: Heebo;
  font-size: 14px;
  font-weight: 400;
}

.stakePage-inner-wrapper {
  width: min(100%, 1242px);
  overflow: hidden;
  padding: 0 20px;
  padding-top: 40px;
}

.stakePage-wrapper * {
  box-sizing: border-box;
}

.stakePage-wrapper p {
  margin-bottom: 0;
}

.stake-connect-button-theme {
  padding: 0;
  min-width: 180px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  font-family: Tyros Pro;
  font-weight: 600;
  border-radius: 12px;
  font-size: 14px;
  border: none;
  margin-bottom: 0;
}
