@media screen and (max-width: 750px) {
    .buyPage-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
    }
    .top-text {
        width: 90%;
        margin-bottom: 40px;
    }
    .buyPage-inner-wrapper {
        width: 92%;
        padding-top: 60px;
    }
    .callout-wrapper {
        display: flex;
        width: 100%;
        background-image: url('assets/Callout.svg');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .external-link-img {
        width: 14px;
    }
}

@media screen and (min-width: 750px) {
    .buyPage-wrapper {
        background-image: url('assets/Backgrounds/Buy_Desktop_1.svg'), url('assets/Backgrounds/Buy_Desktop_2.svg');
        background-repeat: no-repeat;
        background-position: right top -30px, left bottom;
    }
    .buyPage-inner-wrapper {
        width: 86%;
        max-width: var(--max-screen-width);
        padding-top: 100px;
    }
    .callout-wrapper {
        display: none;
    }
    .external-link-img {
        width: 16px;
        height: 16px;
    }
}

.buyPage-wrapper {
    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 200px;
    overflow: hidden;
}
