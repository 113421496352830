.bridge-table-inner-wrapper {
    background: var(--color-bg-primary);
    margin-bottom: 35px;
}

.bridge-table-header {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: var(--color-bg-disabled);
}

.bridge-header-button {
    cursor: pointer;
    width: fit-content;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: flex;
    align-items: center;
}


.bridge-table-inner-wrapper th {
    border-bottom: none;
    font-family: Tyros Pro;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    /* Text/Primary */
    padding-top: 0;
    padding-bottom: 0;
    height: 52px;
}

.bridge-table-inner-wrapper th:nth-child(1) {
    border-radius: 20px 0px 0px 0px;
    padding: 0 25px;
    width: 148px;
}

.bridge-table-inner-wrapper th:nth-child(2) {
    width: 181px;
    padding-left: 0;
}

.bridge-table-inner-wrapper th:nth-child(3) {
    width: 167px;
    padding-left: 0;
}

.bridge-table-inner-wrapper th:nth-child(4) {
    width: 130px;
    text-align: end;
    padding-right: 0;
}

.bridge-table-inner-wrapper th:nth-child(5) {
    width: 112px;
    text-align: end;
    padding-right: 0;
}

.bridge-table-inner-wrapper th:nth-child(6) {
    border-radius: 0px 20px 0px 0px;
    padding: 0 25px;
    width: 160px;
}

.bridge-table-inner-wrapper tbody tr:nth-last-child(1) td:nth-child(1) {
    border-radius: 0px 0px 0px 20px;
}

.bridge-table-inner-wrapper tbody tr:nth-last-child(1) td:nth-child(6) {
    border-radius: 0px 0px 20px 0px;
}

table.bridge-table-inner-wrapper {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-transparent);
    border-radius: 20px;
    color: var(--color-primary);
    width: auto;
    table-layout: fixed;
}

.bridge-table-inner-wrapper td {
    height: 112px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-bottom: none;
    border-top: 1px solid var(--color-transparent);
}

tbody tr:hover td {
    background: #F3F6F7;
}

tbody tr:hover td.bridge-history-loading-wrapper {
    background: inherit;
}

.bridge-more-info {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1px;
    justify-content: flex-end;
}

.bridge-more-info-icon {
    padding: 3px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%
}

.bridge-more-info-icon:hover {
    background: #F3F6F7;
    transition: all 0.2s;
    transition-timing-function: ease-in;
}

.bridge-more-info-icon:hover~.bridge-hover-box {
    display: flex;
}

.bridge-hover-box {
    /* BG/Primary */
    background: var(--color-bg-primary);
    /* Branding/Secondary */
    border: 1px solid #3A78B1;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #3A78B1;
    padding: 8px 16px;
    width: 214px;
    height: 106px;
    display: none;
    left: -45px;
    bottom: 23px;
    position: absolute;
    flex-direction: column;
}

.bridge-hover-box:hover {
    display: flex;
}

td.bridge-table-connect-wrapper {
    height: 298px;
    width: 898px;
    padding: 40px 0px;
}

td.bridge-history-loading-wrapper {
    height: 120px;
    width: 898px;
}

.bridge-history-loading-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
