@media screen and (max-width: 444px) {
    .node-card-selection {
        display: flex;
        width: max-content;
        gap: 16px;
    }

    .node-auto-select {
        flex-direction: column;
        gap: 2px;
    }

    .node-card {
        width: max-content;
    }

    .node-table {
        width: 270px;
    }
}

@media screen and (min-width: 445px) {
    .node-card-selection {
        width: 352px;
        height: 48px;
        display: flex;
        justify-content: space-between;
    }

    .node-button:hover~.node-tooltip {
        display: flex;
        z-index: 5;
    }

    .node-table {
        width: 352px;
    }

    .node-auto-select {
        padding: 0 4px;
        gap: 5px;
    }
}

.node-selection {
    position: relative;
}

.node-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    border-radius: 12px;
    border: 1px solid var(--color-transparent);
    height: 48px;
    background-color: var(--color-bg-primary);
    cursor: pointer;
}

.node-button:hover {
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.node-tooltip {
    display: none;
    position: absolute;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-branding-secondary);
    border: 1px solid var(--color-branding-secondary);
    border-radius: 12px;
    background: var(--color-bg-primary);
    bottom: 51px;
    right: -14px;
}

.node-card {
    position: absolute;
    padding: 28px 32px;
    background: var(--color-bg-primary);
    border-radius: 12px;
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
    display: flex;
    right: 0;
    top: 56px;
    box-shadow: 0px 20px 50px rgba(106, 138, 150, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.node-auto-select {
    display: flex;
    align-items: center;
}

.auto-select-hover {
    background: linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 85.59%, #DAFFF4 132.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.node-table {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    color: var(--color-text-secondary);
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
}

.node-table-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #BDCBD1;
}

.node-rows {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #BDCBD1;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
}

.add-custom-node {
    margin-top: 16px;
    height: 48px;
    border: 1px solid #0A3F52;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--color-primary)
}

.add-custom-node:hover {
    cursor: pointer;
    background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
    border: 1px solid transparent;
}

.add-custom-node:hover>p {
    background: linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 85.59%, #DAFFF4 132.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.host-node {
    text-align: center;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
    margin-top: 16px;
}

.host-node>a {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary);
}