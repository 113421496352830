@import url('https://fonts.googleapis.com/css2?family=Syne&display=swap');
@media screen and (max-width: 350px) {
    .section-subheader {
        font-size: 15px;
    }
}

@media screen and (min-width: 749px) {
    .welcome-image-display {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .introduction-inner-wrapper {
        flex-direction: row;
        align-items: center;
        max-width: var(--max-screen-width);
        width: 84%;
    }
    .welcome-message-wrapper {
        width: 55%;
        color: var(--color-primary);
        justify-content: center;
        max-width: 800px;
    }
    .button-getSWTH {
        width: 200px;
        height: 55px;
        font-size: 18px;
    }
}

@media screen and (max-width: 749px) {
    .introduction-wrapper {
        min-height: 500px;
        margin-bottom: 100px;
        background-size: 200px 100px;
    }
    .introduction-inner-wrapper {
        flex-direction: column-reverse;
        width: 92%;
        align-items: center;
        justify-content: flex-end;
    }
    .welcome-image-display {
        position: relative;
        z-index: -1;
        padding: 80px 0 50px 0;
        object-fit: cover;
        width: 90%;
    }
    .welcome-message-wrapper {
        width: 100%;
        color: var(--color-primary);
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .button-getSWTH {
        width: 100%;
        font-size: 18px;
        height: 50px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1800px) {
    .introduction-wrapper {
        height: 92vh;
        max-height: 900px;
    }
}

@media screen and (min-width: 1800px) {
    .introduction-wrapper {
        padding: 150px 0;
        justify-content: center;
    }
}

.introduction-wrapper {
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: left top;
    align-items: center;
    min-height: 600px;
}

.introduction-inner-wrapper {
    display: flex;
    height: inherit;
}

.welcome-message-wrapper {
    display: flex;
    flex-direction: column;
}

.wave {
    animation-name: wave-animation;
    animation-duration: 0.6s;
    animation-iteration-count: 2;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    50% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
