@media screen and (max-width: 750px) {
    .carbon-section {
        width: 100%;
        margin-top: 60px;
    }
    .carbon-inner-wrapper {
        width: 92%;
    }
}

@media screen and (min-width: 750px) {
    .carbon-section {
        width: 370px;
        margin-right: 40px;
    }
    .carbon-section-holder {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }
    .carbon-inner-wrapper {
        width: 84%;
        max-width: var(--max-screen-width);
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0;
    padding-top: 200px;
}

.carbon-header-holder {
    display: flex;
}

.carbon-image-holder {
    height: 55px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.carbon-title-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-primary);
}

.carbon-section-holder {
    width: 100%;
}

.show-carbon {
    opacity: 1;
    transition: 2s;
    padding-top: 0px;
}