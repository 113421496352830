@media screen and (min-width: 750px) {
    .stake-faq-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Token_Desktop_1.svg'), url('assets/Backgrounds/Migrate_Token_Desktop_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top, left bottom;
    }

    .stake-faq-inner-wrapper {
        width: 962px;
        padding-top: 108px;
    }
}

@media screen and (max-width: 750px) {
    .stake-faq-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
    }
}

.stake-faq-wrapper {
    display: flex;
    justify-content: center;
}

.stake-faq-inner-wrapper {
    margin-left: 16px;
    margin-right: 16px;
}

.stake-faq-inner-wrapper p {
    margin-bottom: 0;
}

.stake-faq-horizontal-divider {
    border-top: 1px solid var(--color-transparent);
}

.stake-faq-wrapper .stake-faq-bottom-text {
    margin-top: 40px;
    margin-bottom: 44px;
    font-family: Heebo;
    font-size: 16px;
    line-height: 24px;
}
