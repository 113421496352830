.stake-redelegation-wrapper {
    width: 100%;
}

.stake-redelegation-inner-wrapper {
    width: 100%;
}

.stake-redelegation-wrapper th {
    border-bottom: none;
    font-family: "Tyros Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 52px;
    padding: 0;
}

.stake-redelegation-wrapper td {
    padding: 0;
    height: 72px;
    line-height: 18px;
}

.stake-redelegation-wrapper th:nth-child(1) {
    border-radius: 12px 0px 0px 0px;
}

.stake-redelegation-wrapper th:nth-last-child(1) {
    border-radius: 0px 12px 0px 0px;
}

.stake-redelegation-wrapper table {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-transparent);
    border-radius: 12px;
    color: var(--color-primary);
    background: var(--color-bg-primary);
}

.stake-redelegation-wrapper td:nth-child(1) {
    width: 299px;
}

.stake-redelegation-wrapper td:nth-child(2) {
    width: 257px;
}

.stake-redelegation-wrapper td:nth-child(3) {
    width: 154px;
    text-align: right;
    font-weight: 400;
}

.stake-redelegation-wrapper td:nth-child(4) {
    width: 208px;
    text-align: right;
}

.stake-redelegation-wrapper td:nth-child(5) {
    width: auto;
    text-align: left;
}

.stake-redelegation-avatar {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: #c4c4c4;
    align-items: center;
}

.stake-redelegation-validator-box {
    display: flex;
    align-items: center;
}

.stake-redelegation-validator-box p {
    height: fit-content;
    margin-left: 8px;
    margin-right: 4px;
}

.stake-redelegation-amount-box {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.stake-more-info {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1px;
}
