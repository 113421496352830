@media screen and (max-width: 510px) {
  .swth-price {
    display: none;
  }
  .stake-top-row-price {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .stake-top-row {
    flex-direction: column-reverse;
  }

  .stake-top-row-functional-components {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
  }

  .stake-top-row-price > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stake-top-row-connection-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }
  .stake-top-row-price {
    width: 100%;
    display: flex;
    padding: 12px 13px;
  }
}

@media screen and (min-width: 750px) {
  .stake-top-row-functional-components {
    display: flex;
    gap: 16px;
  }

  .stake-top-row-price {
    min-width: 226px;
    padding: 12px 16px;
  }

  .stake-top-row-connection-buttons {
    display: flex;
    gap: 16px;
  }
}

.stake-top-row {
  display: flex;
  gap: 16px;
  max-width: 100%;
}

.stake-top-row-proposal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--color-transparent);
  height: 48px;
  background-color: var(--color-bg-primary);
  align-items: center;
  padding: 12px 16px;
}

.stake-top-row-proposal-text {
  display: flex;
  gap: 8px;
  font-size: 16px;
  flex: 1;
}

.stake-primary {
  color: var(--color-primary)
}

.stake-secondary {
  color: var(--color-text-secondary);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stake-top-row-proposal-tag {
  border-radius: 8px;
  width: max-content;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border: 1px solid transparent;
  margin-left: 2px;
}

.voting {
  background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
}
.voting > p {
  background: linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 85.59%, #DAFFF4 132.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.deposit {
  background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 69.96%, #DAFFF4 132.29%) border-box;
}
.deposit > p {
  background: linear-gradient(87.57deg, #3A78B1 15.39%, #4FC1C0 85.59%, #DAFFF4 132.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.passed {
  background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #3C9D40 15.39%, #05C185 60.89%, #86E7C8 132.29%) border-box;
}

.passed > p {
  background: linear-gradient(87.57deg, #3C9D40 15.39%, #05C185 60.89%, #86E7C8 132.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.rejected {
  background: linear-gradient(white, white) padding-box, linear-gradient(87.57deg, #DC6D5E 15.39%, #E27625 69.96%, #F3BA2F 132.29%) border-box;
}

.rejected > p {
  background: linear-gradient(87.57deg, #DC6D5E 15.39%, #E27625 69.96%, #F3BA2F 132.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.stake-top-row-proposal-tag::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  border: 1px solid transparent;
  border-radius: 8px;
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

.stake-top-row-price {
  gap: 8px;
  font-size: 16px;
  overflow: hidden;
  border-radius: 12px;
  border: 1px solid var(--color-transparent);
  height: 48px;
  background-color: var(--color-bg-primary);
  display: flex;
  align-items: center;
}

.stake-left-chevron {
  position: relative;
  left: 5px;
}

.stake-right-chevron {
  position: relative;
  right: 5px;
}

.stake-top-row-wallet {
  min-width: 180px;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--color-transparent);
  height: 48px;
  background-color: var(--color-bg-primary);
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.stake-top-row-wallet:hover {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(87.57deg, #3a78b1 15.39%, #4fc1c0 69.96%, #dafff4 132.29%) border-box;
  border: 1px solid transparent;
}

.stake-connect-button-theme {
  width: 100%;
}