@media screen and (max-width: 650px) {
    .migration-card {
        width: 100%;
        margin-bottom: 24px;
    }
    .migration-section {
        flex-direction: column;
    }
    .top-text {
        width: 90%;
        margin-bottom: 40px;
    }
    .migratePage-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
    }
    .migratePage-inner-wrapper {
        width: 92%;
        padding-top: 60px;
    }
    .migrate-callout-wrapper {
        display: flex;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

@media screen and (min-width: 650px) and (max-width: 1100px) {
    .migration-card {
        width: 49%;
        margin-bottom: 24px;
    }
    .migration-section {
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .top-text {
        width: 80%;
        margin-bottom: 80px;
    }
    .migratePage-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Mobile_1.svg'), url('assets/Backgrounds/Migrate_Mobile_2.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: right top 70px, left bottom;
    }
    .migratePage-inner-wrapper {
        width: 92%;
        padding-top: 100px;
    }
    .migrate-callout-wrapper {
        display: none;
    }
}

@media screen and (min-width: 1100px) {
    .migration-card {
        width: 32.5%;
        margin-bottom: 10px;
    }
    .migration-section {
        justify-content: space-between;
    }
    .top-text {
        width: 75%;
        margin-bottom: 80px;
    }
    .migratePage-wrapper {
        background-image: url('assets/Backgrounds/Migrate_Desktop_1.svg'), url('assets/Backgrounds/Migrate_Desktop_2.svg');
        background-repeat: no-repeat;
        background-position: right top 70px, right bottom 40px;
        padding-top: 100px;
    }
    .migratePage-inner-wrapper {
        width: 86%;
        max-width: 1400px;
        padding-top: 100px;
    }
    .migrate-callout-wrapper {
        display: none;
    }
}

.migration-card {
    display: flex;
    flex-direction: row;
}

.migratePage-wrapper {
    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 200px;
}

.migration-section {
    margin-top: 3vh;
    display: flex;
    flex-wrap: wrap;
}

.small-text {
    font-size: 16px;
    margin-top: 10px;
    font-family: Source Sans Pro
}

.popup-animation {
    width: 220px;
    height: 220px;
}

.loading-icon {
    width: 25px;
    height: 25px;
}

.loading-holder {
    width: 120px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.card-description {
    word-wrap: break-word;
    overflow-wrap: normal;
    font-family: "Heebo";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}
