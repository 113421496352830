@media screen and (max-width: 1030px) {
  .stake-info-headers {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .stake-info-cards {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .stake-info-card-1 {
    height: 170px;
    width: 100%;
    padding: 20px 16px;
  }

  .stake-info-card-1 .stake-info-description-primary {
    font-size: 16px;
    line-height: 20px;
    height: 20px;
  }

  .stake-info-card-1 .stake-info-description-secondary {
    line-height: 18px;
    margin-top: 4px;
    font-size: 14px;
  }

  .stake-info-card-1 .stake-info-title {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 20px;
  }
}


@media screen and (min-width: 1031px) {
  .stake-info-headers {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .stake-info-cards {
    margin-top: 16px;
    display: flex;
    gap: 24px;
  }

  .stake-info-card-1 {
    height: 228px;
    width: 428px;
    padding: 26px 24px;
  }

  .stake-info-card-1 .stake-info-description-primary {
    font-size: 28px;
    line-height: 36px;
  }

  .stake-info-card-1 .stake-info-description-secondary {
    line-height: 26px;
    margin-top: 6px;
    font-size: 20px;
  }

  .stake-info-card-1 .stake-info-title {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 20px;
  }
}

.stake-info {
  margin-top: 40px;
  width: 100%;
  font-family: Tyros Pro;
  font-weight: 700;
}

.stake-info-headers>p {
  font-size: 20px;
  line-height: 24px;
}

.stake-info-cards>div {
  border-radius: 20px;
  border: 1px solid var(--color-transparent);
  background: var(--color-bg-primary);
}

.stake-info-card-1 .stake-info-title {
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  gap: 2px;
  height: 20px;
}

.stake-info-card-1 .stake-info-description-secondary {
  font-weight: 400;
  color: var(--color-text-secondary);
}