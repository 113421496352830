.stake-your-delegation-mobile-card-wrapper {
    border-radius: 20px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    background: none; 
    overflow: hidden;
    height: fit-content;
    margin-top: 16px;
    padding: 20px 16px;
    font-size: 16px;
    line-height: 24px;
}
.stake-your-delegation-mobile-stats {
    margin-top: 10px;
}

.stake-your-delegation-mobile-stats>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.stake-your-delegation-mobile-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.empty-mobile-card {
    border-radius: 10px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: none;
    overflow: hidden;
    height: fit-content;
    margin-top: 16px;
}