.address-label-bolded {
    font-family: Tyros Pro;
    font-weight: 700;
    font-size: 14px;
}

.address-label-unbolded {
    font-family: Heebo;
    font-weight: 400;
}

.address-label a {
    color: inherit;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 18px;
}

.address-label-external-link:hover {
    color: var(--color-branding-secondary) !important;
}

.address-label-disabled * {
    opacity: 0.5;
}
