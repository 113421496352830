@media screen and (max-width: 750px) {
    .delegation-warning-popup-text {
        font-size: 11px;
    }
}

@media screen and (min-width: 750px) {
    .delegation-warning-popup-text {
        font-size: 14px;
    }
}

.delegation-warning-popup-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 14px;
}