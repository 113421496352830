@media screen and (max-width: 750px) {
  .stake-validators-headers{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .stake-validators-search-bar {
    flex-direction: column;
  }
  .stake-validators-search-bar {
    gap: 8px;
  }
}


@media screen and (min-width: 750px) {
  .stake-validators-headers {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .stake-validators-search-bar {
    gap: 16px;
  }
}

.stake-validators-info {
  width: 100%;
  margin-top: 56px;
}

.stake-validators-search-bar {
  margin-top: 16px;
  display: flex;
}

.stake-validators-show-inactive {
  padding: 14px 16px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid var(--color-transparent);
  height: 48px;
  border-radius: 12px;
  background: var(--color-bg-primary);
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
}
