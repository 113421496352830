.SWTH-compound-token {
    height: 28px;
    width: 28px;
    position: relative;
    transform: scale(0.833);
}

.SWTH-chain-box {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 14px;
    width: 14px;
    border: 1px solid var(--color-bg-disabled);
    border-radius: 4px;
    background: var(--color-bg-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}
