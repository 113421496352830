.toast-messages-wrapper {
    position: relative;
    top: 16px;
    right: 16px;
    align-self: flex-end;
    z-index: 2;
    font-family: 'Tyros Pro';
    font-size: 12px;
    font-weight: 700;
    color: #0A3F52;
}

.toast-messages-wrapper p {
    margin-bottom: 0;
}

.toast-box {
    width: 359px;
    border-radius: 12px;
    position: relative;
    margin-bottom: 10px;
    animation: toast-message-fadein 1s ease-in-out;
}

.toast-box-inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 17px 16px;
}

.toast-success {
    background: #EFFAEF;
    border: 1px solid #3C9D40;
}

.toast-success .toast-loading-bar {
    background: #3C9D40;
}

.toast-error {
    background: #FCF1EF;
    border: 1px solid #DC6D5E;
}

.toast-error .toast-loading-bar {
    background: #DC6D5E;
}

.toast-box-contents {
    display: flex;
    align-items: center;
    height: 38px;
    width: 100%;
}

.toast-box-message {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    line-height: 16px;
    width: 216px;
}

.toast-box-message span {
    font-weight: 400;
}

.toast-icons {
    margin-left: 24px;
    width: 44px;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
}

.toast-icons * {
    cursor: pointer;
}

.toast-error-contents {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 38px;
    padding-right: 59px;
}

.toast-error-text {
    font-family: 'Heebo';
    font-weight: 400;
    line-height: 18px;
}

.toast-error-text a {
    text-decoration: underline;
    color: inherit;
}

.toast-error-contents {
    font-family: 'Heebo';
    font-weight: 400;
    line-height: 18px;
}

.toast-error-contents hr {
    margin: 0;
    border-top: 1px solid #DC6D5E;
}

.toast-loading-wrapper {
    position: absolute;
    bottom: -1px;
    height: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
}

.toast-loading-bar {
    height: 4px;
    width: 0;
    animation: toast-loading 8s ease-in-out;
}

@keyframes toast-loading {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes toast-message-fadein {
    from {
        right: -16px;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}
