.stake-your-delegation-wrapper {
    width: 100%;
    overflow: auto;
}

.stake-your-delegation-inner-wrapper {
    overflow: auto;
    width: 100%;
}

.stake-your-delegation-wrapper th {
    border-bottom: none;
    font-family: 'Tyros Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 52px;
    padding: 0;
}

.stake-your-delegation-wrapper td {
    padding: 0;
    height: 72px;
    line-height: 18px;
}

.stake-your-delegation-wrapper th:nth-child(1) {
    border-radius: 12px 0px 0px 0px;
}

.stake-your-delegation-wrapper th:nth-last-child(1) {
    border-radius: 0px 12px 0px 0px;
}

.stake-your-delegation-wrapper table {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-transparent);
    border-radius: 12px;
    color: var(--color-primary);
    background: var(--color-bg-primary);
}

.stake-your-delegation-wrapper td:nth-child(1) {
    width: 30%;
}

.stake-your-delegation-wrapper td:nth-child(2) {
    width: 15%;
    text-align: right;
}

.stake-your-delegation-wrapper td:nth-child(3) {
    width: 25%;
    text-align: right;
    font-weight: 400;
}

.stake-your-delegation-wrapper td:nth-child(4) {
    width: auto;
    text-align: left;
}

.stake-your-delegation-notes-buttons {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
}

.delegation-table-head > tr {
    width: 75%;
}