@media screen and (max-width: 750px) {
    .popup {
        width: 342px;
        padding: 32px 24px;
    }
}

@media screen and (min-width: 750px) {
    .popup {
        width: 440px;
        padding: 32px;
    }
}

.popup {
    border: 0.5px solid #E5E5E5;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(106, 138, 150, 0.5);
    background-color: white;
    color: rgba(10, 63, 82, 1);
    border-radius: 20px;
}

.popup p {
    margin-bottom: 0;
}


.popup-msg-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.close-button-wrapper {
    text-align: right;
    margin-top: -3px;
    cursor: pointer;
}

.popup-button {
    height: 48px;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
}

.pop-up-header {
    display: flex;
    width: 100%;
    color: var(--color-primary);
    font-size: 20px;
    justify-content: space-between;
    margin-bottom: 24px;
    line-height: 24px;
}

.popup-transaction-link {
    color: inherit;
    margin-top: 26px;
    margin-bottom: 22px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 14px;
}
