@media screen and (max-width: 750px) {
    .upcoming-inner-wrapper {
        align-items: flex-start;
        width: 92%;
    }
    .upcoming-image-holder {
        width: 65px;
        height: 65px;
        margin-bottom: 15px;
    }
    .stay-tuned-wrapper {
        display: flex;
        width: 95%;
        justify-content: flex-end;
    }
    .upcoming-wrapper {
        margin-left: -200px;
    }
}

@media screen and (min-width: 750px) {
    .upcoming-wrapper {
        margin-top: 130px;
        margin-left: -200px;
        padding: 100px 0 150px 0;
    }
    .upcoming-inner-wrapper {
        align-items: flex-end;
        width: 84%;
        max-width: var(--max-screen-width);
    }
    .upcoming-section {
        width: 52%;
        display: flex;
        flex-direction: row;
    }
    .upcoming-header {
        width: 52%;
    }
    .upcoming-image-holder {
        width: 65px;
        height: 65px;
        margin-right: 20px;
    }
    .stay-tuned-wrapper {
        width: 52%;
    }
}

.upcoming-inner-wrapper {
    display: flex;
    flex-direction: column;
}

.upcoming-wrapper {
    opacity: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.show {
    opacity: 1;
    transition: 2s;
    margin-left: 0;
}