.stake-faq-item {
    display: flex;
    flex-direction: column;
    gap: 27px;
    font-family: Heebo;
    font-size: 16px;
    line-height: 24px;
}

.stake-faq-item-header {
    font-size: 20px;
    display: flex;
    gap: 8px;
}
