.bridge-progress-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 60px;
    width: 100%;
    height: 54px;
}


.bridge-progress-token-container {
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 5px 10px 3px var(--color-transparent);
    height: 54px;
    width: 54px;
    display: grid;
    place-items: center;
    background-color: var(--color-bg-hover);
}

.bridge-progress-token-container div {
    transform: scale(1.25);
    display: grid;
    place-items: center;
}
