@media screen and (max-width: 500px) {
    .carbon-wrapper {
        width: 196px;
        height: 24px;
    }
    .footer-nav {
        height: 250px;
        justify-content: space-between;
        padding: 10% 5% 0 5%;
        width: 92%;
    }
    .footer-carbon {
        height: 100px;
        width: 92%;
        font-size: 13px;
    }
    .footer-wrapper {
        height: fit-content
    }
    .footer-nav-1 {
        width: 50%;
        font-size: 15px;
    }
    .footer-link {
        font-size: 16px;
    }
}

@media screen and (min-width: 500px) {
    .footer-carbon {
        width: 84%;
        max-width: var(--max-screen-width);
        height: 40%;
    }
    .footer-wrapper {
        height: 620px;
    }
    .footer-nav {
        height: 60%;
        width: 84%;
        max-width: var(--max-screen-width);
        padding-top: 70px;
    }
    .footer-nav-1 {
        width: 30%;
    }
    .footer-link {
        font-size: 19px;
    }
    .carbon-wrapper {
        width: 330px;
        height: 32px;
    }
}

.footer-wrapper {
    width: 100%;
    background-color: #04171E;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
}

.footer-nav {
    display: flex;
    flex-direction: row;
}

.footer-nav-1 {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-between;
}

.footer-nav-2 {
    display: flex;
    flex-direction: column;
    height: 32%;
    justify-content: space-between;
}

.footer-carbon {
    border-top: 0.5px solid var(--color-bg-primary);
    font-family: Tyros Pro;
    color: var(--color-bg-primary);
    align-items: center;
    justify-content: center;
    display: flex;
}

.footer-link {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    width: fit-content;
}

.footer-link:hover,
.footer-link:focus {
    color: #3A78B1;
}