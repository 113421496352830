.stake-faq-header {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: 48px;
}

.stake-faq-header-title {
    font-size: 36px;
}

.stake-faq-header-secondary {
    font-size: 20px;
    margin-bottom: 8px;
}
