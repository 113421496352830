input[type=range] {
    width: 99%;
    margin-top: 5px;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    border-radius: 4px;
}

input[type=range]:not([disabled])::-webkit-slider-runnable-track {
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
    -webkit-appearance: none;
    margin-top: -7.5px;
}

input[type=range]:not([disabled])::-webkit-slider-thumb {
    cursor: pointer;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    background: var(--color-bg-disabled);
    border-radius: 4px;
}

input[type=range]:not([disabled])::-moz-range-track {
    cursor: pointer;
}

input[type=range]::-moz-range-progress {
    background: var(--color-primary);
    height: 4px;
}

input[type=range]::-moz-range-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
}

input[type=range]:not([disabled])::-moz-range-thumb {
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]:not([disabled])::-ms-track {
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: var(--color-primary);
    border-radius: 4px;
}

input[type=range]::-ms-fill-upper {
    background: var(--color-bg-disabled);
    border-radius: 4px;
}

input[type=range]::-ms-thumb {
    border: 4px solid var(--color-primary);
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: var(--color-bg-primary);
}

input[type=range]:not([disabled])::ms-thumb {
    cursor: pointer;
}

.slider-numbers-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    padding-left: 2px;
}

.slider-numbers-bar span {
    cursor: default;
}

:not(.slider-amount-disabled) > .slider-numbers-bar span {
    cursor: pointer;
}


:not(.slider-amount-disabled) > .slider-numbers-bar span:hover {
    color: var(--color-branding-secondary)
}