@media screen and (max-width: 750px) {
    .undelegate-popup-wrapper {
        width: 342px;
        height: 390px;
        padding: 24px 16px;
    }

    .undelegate-details {
        margin-bottom: 16px;
    }

    .undelegate-popup-wrapper>div {
        margin-top: 16px;
    }

    .undelegate-details div:first-child {
        margin-bottom: 16px;
    }

    .undelegate-details div:nth-child(2) {
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 750px) {
    .undelegate-popup-wrapper {
        width: 440px;
        height: 422px;
        padding: 40px 32px;
    }

    .undelegate-details {
        margin-bottom: 24px;
    }

    .undelegate-popup-wrapper>div {
        margin-top: 8px;
        margin-bottom: 24px;
    }

    .undelegate-details div:first-child {
        margin-bottom: 8px;
    }

    .undelegate-details div:nth-child(2) {
        margin-bottom: 8px;
    }
}

.undelegate-popup-wrapper {
    border-radius: 10px;
    border: 1px solid var(--color-transparent);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: fit-content;
    font-family: Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.success-img {
    height: 74px;
    width: 74px;
}

.undelegate-details {
    width: 100%;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 400;
}

.undelegate-details div {
    display: flex;
    justify-content: space-between;
}


.undelegate-details div:nth-child(4) {
    color: var(--color-text-secondary);
    font-size: 14px;
}

.popup-button {
    font-size: 14px;
}