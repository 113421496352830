.external-link-component {
    font-size: 16px;
    display: flex;
    font-family: Tyros Pro;
    font-weight: 700;
    overflow: hidden;
    color: inherit;
}

.external-link-component:focus {
    color: inherit;
}
