@media screen and (max-width: 750px) {
    .reward-card-back-button-wrapper {
        width: 342px;
    }

    .reward-card {
        width: 342px;
        min-width: 342px;
        padding: 24px 16px;
    }

    .reward-card-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .reward-card-tokens-container {
        margin-top: 16px;
    }

    .stake-reward-button {
        height: 48px;
        pointer-events: auto;
        font-size: 14px;
        width: 100%;
    }
}

@media screen and (min-width: 750px) {
    .reward-card-back-button-wrapper {
        width: 512px;
    }

    .reward-card {
        width: 512px;
        min-width: 512px;
        padding: 40px 32px;
    }

    .reward-card-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .reward-card-tokens-container {
        margin-top: 26px;
    }

    .stake-reward-button {
        height: 48px;
        pointer-events: auto;
        border: 0;
        font-size: 14px;
        margin-right: -0.5px;
        width: 180px;
    }
}

.reward-card-back-button {
    display: flex;
    align-items: center;
    width: 69px;
    gap: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
}

.reward-card-back-button:hover {
    color: var(--color-branding-secondary);
}

.reward-card {
    border-radius: 10px;
    border: 1px solid var(--color-transparent);
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 116px;
}

.reward-card-text-secondary {
    color: var(--color-text-secondary);
}

.reward-card-title {
    font-size: 28px;
    line-height: 36px;
}

.reward-card-tokens-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reward-card-tokens-container>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-family: 'Heebo';

}

.reward-card-popup-amount {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
}